import { Button, Card, Col, Container, Image, Modal, Ratio, Row } from "react-bootstrap"
import "../Show/Show.css"
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetExhibitionBySeoUrlQuery } from "../../features/CallApi";
import { LoadScreenMin } from "../UI/LoadScreenMin";
import parse from 'html-react-parser';
import { format } from "date-fns";
import { ru } from "date-fns/locale";
import PhotoAlbum from 'react-photo-album';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/plugins/captions.css";

export const Exhibition = () => {
    const { seoUrl } = useParams("seoUrl")
    const [t] = useTranslation("global");
    const [selLng, setSelLng] = useState('')
    const navigate = useNavigate()
    // const { data: photosData, refetch } = useGetShowGalleryQuery(id)


    useEffect(() => {
        if (localStorage.getItem("selLng") !== null) {
            setSelLng(localStorage.getItem("selLng"))
        }
    }, [t])

    const { data, isLoading, status } = useGetExhibitionBySeoUrlQuery({ seoUrl, selLng });

    const [index, setIndex] = useState(-1);
    const [photos, setPhotos] = useState()

    const loaction = useLocation()
    const [wide, setWide] = useState();
    const [wideHead, setWideHead] = useState();

    // useEffect(() => {
    //     if (window.innerWidth <= 768) {
    //         setWide(false)
    //     }
    //     if (window.innerWidth > 768) {
    //         setWide(true)
    //     }
    //     if (window.innerWidth <= 1160) {
    //         setWideHead(false)
    //     }
    //     if (window.innerWidth > 1160) {
    //         setWideHead(true)
    //     }
    //     refetch()
    // }, [])

    // useEffect(() => {
    //     if (photosData?.length > 0 && data != null) {
    //         const links = [];
    //         (photosData).forEach(item => {
    //             links.push({ src: `/api/shows/gallery-photo/${item.id}`, width: item.photoWidth, height: item.photoHeight, alt: data.name })
    //         });
    //         setPhotos(links)
    //     }
    // }, [photosData, data])

    // useEffect(() => {
    //     const resizeHandeler = (e) => {
    //         if (e.target.window.innerWidth <= 768) {
    //             setWide(false)
    //         }
    //         if (e.target.window.innerWidth > 768) {
    //             setWide(true)
    //         }
    //         if (e.target.window.innerWidth <= 1160) {
    //             setWideHead(false)
    //         }
    //         if (e.target.window.innerWidth > 1160) {
    //             setWideHead(true)
    //         }
    //         // console.log(e.target.window.innerWidth)
    //         // console.log(e.target.window.innerHeight)
    //     }

    //     window.addEventListener('resize', resizeHandeler)
    //     return function () {
    //         window.addEventListener('resize', resizeHandeler)
    //     }
    // }, [loaction])

    // useEffect(() => {
    //     if (status === "rejected") {
    //         navigate("/notfonund")
    //     }
    // }, [status])

    return (
        <>
            {!isLoading ? <>
                {data && <>
                    <div className="haed-layout">
                        <div className="head-layout-img" style={{ backgroundImage: data.headLayoutImageType ? `url(/api/exhibitions/head-wide-img/${data.id})` : "url(/Show/091A8332.jpg)" }}>
                        </div>
                        <div className="head-mask">
                        </div>
                    </div>
                    <Container className="pt-3 ">
                        <Row className="justify-content-center">
                            <Col lg={5} className="text-center d-none d-lg-block">
                                <img src={`/api/exhibitions/afisha-img/${data.id}`} alt={`Афиша ${data.name}`} style={{ maxHeight: '55vh' }} className="img-fluid" />
                            </Col>
                            {data?.linkVideo !== null ?
                                <>
                                    {wide === true &&
                                        <Col lg={7}>
                                            <Ratio aspectRatio="16x9">
                                                <iframe className="embed-responsive-item" src={data.linkVideo} allowFullScreen={true}>
                                                </iframe>
                                            </Ratio>
                                        </Col>
                                    }
                                </>
                                :
                                <Col lg={5} className="text-center d-none d-lg-block">
                                    <img src={`/api/exhibitions/afisha-img/${data.id}`} alt={`Афиша ${data.name}`} style={{ maxHeight: '55vh' }} className="img-fluid" />
                                </Col>
                            }
                            <Col lg={5}>
                                <h3><strong>{data.name}</strong></h3>
                                <p>
                                    <strong>
                                        {data.shortDescription}
                                    </strong>
                                </p>
                                {/* <p>
                                    {t("show.director")} — <strong>
                                        {data.director}
                                    </strong>
                                </p> */}
                                {/* <p>
                                    {t("show.duration")}: {data.duration}
                                </p> */}
                                <p>
                                    {t("show.age")} <strong>{data.ageCategory}+</strong>
                                </p>
                                {/* <p>
                                    <i>{t("show.afterStart")}</i>
                                </p> */}
                                <div className="mb-5 fst-italic text-end fw-bold">
                                    <div>
                                        {data?.quote}
                                    </div>
                                </div>
                            </Col>

                        </Row>
                        {/* <Row className="justify-content-around">
                            <Col lg={"auto"} className="text-center">

                            </Col>
                            <Col lg={6}>

                            </Col>
                        </Row> */}
                        {data?.linkVideo && <>
                            {
                                wide === false && <>
                                    <Ratio aspectRatio="16x9 mb-3">
                                        <iframe className="embed-responsive-item" src={data.linkVideo} allowFullScreen={true}>
                                        </iframe>
                                    </Ratio>
                                </>
                            }
                        </>
                        }
                        <div className="mt-3">
                            {data.description && parse(data.description)}
                        </div>
                        {/* {photosData &&
                            <div>
                                <PhotoAlbum columns={3} layout="masonry" photos={photos} onClick={({ index }) => setIndex(index)} />
                                <Lightbox
                                    slides={photos}
                                    open={index >= 0}
                                    index={index}
                                    close={() => setIndex(-1)}
                                    // enable optional lightbox plugins
                                    plugins={[Slideshow, Thumbnails, Zoom, Captions]}
                                />
                            </div>
                        } */}
                    </Container>
                </>
                }
            </> : <LoadScreenMin />}
        </>
    )
}