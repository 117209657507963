import { Col, Container, Image, Row } from "react-bootstrap";
import { SwiperAfisha } from "./UI/SwiperAfisha";
import { useLocation } from "react-router-dom";
import { Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetAfishaQuery } from "../features/CallApi";
import { LoadScreenMin } from "./UI/LoadScreenMin";
import { NewsHomePage } from "./News/NewsHomePage";
import { Exhibitions } from "./Exhibition/Exhibitions";
import { HomeHeadVideo } from "./HomeHeadVideo";
import { HomeHeadVideoV } from "./HomeHeadVideoV";
import ContentLoader from "react-content-loader";
// import FlipCountdown from '@rumess/react-flip-countdown';
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import '@leenguyen/react-flip-clock-countdown/dist/index.css';
import { Helmet } from "react-helmet";
import { Repertoire } from "./Show/Repertoire";
import { ScheduleNewYear } from "./UI/ScheduleNewYear";

const CountdownTimer = ({ }) => {
  return (
    <>
      <div className="mb-3 mt-3">
        <div className="col-md-8 lc-event-widget lc-cinema-widget lc-mob-mt-1 m-auto">
          <img className="img-fluid" src="/Timer/7437047a8d6a18460bb9150ec7c7602c.jpg" alt="LC10" />
        </div>
      </div>
      <div className="col-lg-7 col-md-8 lc-event-widget lc-cinema-widget lc-mob-mt-1 m-auto mt-3">
        <div style={{ width: "100%" }}>
          <FlipClockCountdown
            className="text-center m-auto justify-content-center"
            to={new Date("2024-12-25 00:00:00")}

            labels={['Дней', 'Часов', 'Минут', 'Секунд']}
            labelStyle={{ fontSize: 18, fontWeight: 500, textTransform: 'uppercase' }}
            digitBlockStyle={{ width: 30, height: 45, fontSize: 30, alignItems: 'center', color: "#e1c57b" }}
            // dividerStyle={{ color: '#e1c57b', height: 0 }}
            separatorStyle={{ size: '0', display: "none" }}
            duration={0.7}
          />
        </div>
      </div>

    </>
  );
}

export const Home = ({ selLanguage }) => {

  const { loaction } = useLocation();
  const [wide, setWide] = useState();
  const { data: dataAfisha, isLoading: loadingAfisha } = useGetAfishaQuery({ selLanguage })



  const [t] = useTranslation("global");

  useEffect(() => {
    if (window.innerWidth < window.innerHeight) {
      setWide(false)
    }
    if (window.innerWidth > window.innerHeight) {
      setWide(true)
    }
  }, [])

  useEffect(() => {
    const resizeHandeler = (e) => {
      if (e.target.window.innerWidth < e.target.window.innerHeight) {
        setWide(false)
      }
      if (e.target.window.innerWidth > e.target.window.innerHeight) {
        setWide(true)
      }
      // console.log(e.target.window.innerWidth)
      // console.log(e.target.window.innerHeight)
    }

    window.addEventListener('resize', resizeHandeler)
    return function () {
      window.addEventListener('resize', resizeHandeler)
    }
  }, [loaction])

  return (
    <>
      <Helmet>
        <title>{t("mainTag.title")}</title>
        <meta name="description" content='Первое в России шоу-пространство "Ленинград Центр"'></meta>
        <meta name="keywords" content="шоу- пространство, шоу пространство, ленинград центр, центр ленинград, мюзикл, шоу, представление, афиша"></meta>
        {/* <meta property="og:image" content={data.headLayoutImageType ? `/api/shows/head-wide-img/${data.id})` : "/Show/091A8332.jpg"} /> */}
        <meta property="og:title" content={t("mainTag.title")} />
        <meta property="og:description" content='Первое в России шоу-пространство "Ленинград Центр"' />
        <meta property="og:image" content={'https://leningradcenter.ru/lc-logo.png'} />
        <meta property="og:type" content="theatre" />
        <meta property="og:url" content="https://leningradcenter.ru/" />
      </Helmet>
      <Suspense fallback={<LoadScreenMin height={100} />}>
        {wide !== null &&
          <>
            {wide === true &&
              <>
                {/* {console.log(wide)} */}
                <HomeHeadVideo />
              </>}
            {wide === false &&
              <>
                {/* {console.log(wide)} */}
                <HomeHeadVideoV />
              </>

            }
          </>}
      </Suspense>
      <Container id="afisha">

        <Suspense fallback={<LoadScreenMin height={100} />}>
          {!loadingAfisha ?
            <>
              {dataAfisha?.length > 0 &&
                <div className="mt-1 mb-1 pt-3 pb-3 border-bottom">
                  <h2>{t("general.playbill")}</h2>
                  <SwiperAfisha items={dataAfisha} />
                </div>
              }
              {dataAfisha?.length === 0 &&
                <div className="mt-1 mb-1 pt-3 pb-3 border-bottom">
                  <h2>{t("general.repertoire")}</h2>
                  <Repertoire selLanguage={selLanguage} />
                </div>
              }

            </>
            :
            <div className="mt-1 mb-1 pt-3 pb-3 border-bottom" >
              <h2>{t("general.playbill")}</h2>
              <Row className="g-3 justify-content-around">
                <Col
                  xs={6}
                  md={4}
                >
                  <ContentLoader
                    className={'img-fluid'}
                    backgroundColor="#dedede"
                    foregroundColor="#ecebeb"
                    speed={0.5}

                    viewBox="0 0 1080 1620">
                    {/* Only SVG shapes */}
                    <rect x="0" y="0" rx="5" ry="5" width="100%" height={'100%'} />
                  </ContentLoader>
                </Col>
                <Col
                  xs={6}
                  md={4}
                >
                  <ContentLoader
                    className={'img-fluid'}
                    backgroundColor="#dedede"
                    foregroundColor="#ecebeb"
                    speed={0.5}

                    viewBox="0 0 1080 1620">
                    {/* Only SVG shapes */}
                    <rect x="0" y="0" rx="5" ry="5" width="100%" height={'100%'} />
                  </ContentLoader>
                </Col>
              </Row>
            </div>

          }
        </Suspense>
        {/* <ScheduleNewYear /> */}
        <Exhibitions maxCount={1} />


        {/* <CountdownTimer /> */}

        <NewsHomePage selLng={selLanguage} />
      </Container >
    </>
  );
}
