import { Button, ButtonGroup, Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap"
import { useForm } from "react-hook-form";
import { useAddShowAfishaMutation, useDeleteShowAfishaMutation, useGetShowDetailsByIdQuery, usePutShowByIdMutation } from "../../../features/CallApi";
import { LoadScreenMin } from "../../UI/LoadScreenMin";
import { useEffect } from "react";
import { useState } from "react";
import 'react-quill/dist/quill.snow.css';
import { ReactQuillLC } from "../../UI/ReactQuillLC";

export const ShowEdit = ({ showEditForm, setShowEditForm, showId, setShowId, refetchShows }) => {
    const { register, handleSubmit, reset, formState: { errors }, setValue } = useForm();
    const [hidden, setHidden] = useState(true)
    const [isLoading, setIsLoading] = useState(true)
    const [buyButtonStatus, setBuyButtonStatus] = useState(false);


    const [valueEditor, setValueEditor] = useState('');
    const [valueEditorEn, setValueEditorEn] = useState('');
    const [changingPhoto, setChangingPhoto] = useState();
    const [changingHeadPhoto, setChangingHeadPhoto] = useState();
    const [changingHeadWidePhoto, setChangingHeadWidePhoto] = useState();


    const { data, isLoading: isLoadingData, refetch } = useGetShowDetailsByIdQuery(showId)
    const [putShowById, { isLoading: isPuting }] = usePutShowByIdMutation()
    const [addShowAfisha, { isLoading: postingImg }] = useAddShowAfishaMutation();
    const [deleteShowAfisha,] = useDeleteShowAfishaMutation();
    const [imgSizeError, setImgSizeError] = useState();


    const onImageChange = (event) => {
        setChangingPhoto(true)
        if (event.target.files[0].size < 2097153) {
            setImgSizeError(null)
            if (event.target.files && event.target.files[0]) {
                const reader = new FileReader(event.target.files[0]);
                reader.onloadend = () => {
                    const body = {
                        id: showId,
                        image: reader.result,
                        imageType: event.target.files[0].type
                    }
                    addShowAfisha({ id: showId, imageBlock: "afisha", ...body })
                }
                reader.readAsDataURL(event.target.files[0])
            }
            setTimeout(() => {
                refetch()
                setChangingPhoto(false)
            }, 2000)
        } else {
            setImgSizeError("Максимальный размер фото 2 Мб")
            alert("Максимальный размер фото 2 Мб")
            setChangingPhoto(false)
        }
    }

    const onHeadImageChange = (event) => {
        setChangingHeadPhoto(true)
        if (event.target.files[0].size < 2097153) {
            setImgSizeError(null)
            if (event.target.files && event.target.files[0]) {
                const reader = new FileReader(event.target.files[0]);
                reader.onloadend = () => {
                    const body = {
                        id: showId,
                        image: reader.result,
                        imageType: event.target.files[0].type
                    }
                    addShowAfisha({ id: showId, imageBlock: "head", ...body })
                }
                reader.readAsDataURL(event.target.files[0])
            }
            setTimeout(() => {
                refetch()
                setChangingHeadPhoto(false)
            }, 2000)
        } else {
            setImgSizeError("Максимальный размер фото 2 Мб")
            alert("Максимальный размер фото 2 Мб")
            setChangingHeadPhoto(false)
        }
    }
    const onHeadImageWideChange = (event) => {
        setChangingHeadWidePhoto(true)
        if (event.target.files[0].size < 2097153) {
            setImgSizeError(null)
            if (event.target.files && event.target.files[0]) {
                const reader = new FileReader(event.target.files[0]);
                reader.onloadend = () => {
                    const body = {
                        id: showId,
                        image: reader.result,
                        imageType: event.target.files[0].type
                    }
                    addShowAfisha({ id: showId, imageBlock: "headWide", ...body })
                }
                reader.readAsDataURL(event.target.files[0])
            }
            setTimeout(() => {
                refetch()
                setChangingHeadWidePhoto(false)
            }, 2000)
        } else {
            setImgSizeError("Максимальный размер фото 2 Мб")
            alert("Максимальный размер фото 2 Мб")
            setChangingHeadWidePhoto(false)
        }
    }

    const onTicketChange = (event) => {
        setChangingHeadWidePhoto(true)
        if (event.target.files[0].size < 2097153) {
            setImgSizeError(null)
            if (event.target.files && event.target.files[0]) {
                const reader = new FileReader(event.target.files[0]);
                reader.onloadend = () => {
                    const body = {
                        id: showId,
                        image: reader.result,
                        imageType: event.target.files[0].type
                    }
                    addShowAfisha({ id: showId, imageBlock: "ticket", ...body })
                }
                reader.readAsDataURL(event.target.files[0])
            }
            setTimeout(() => {
                refetch()
                setChangingHeadWidePhoto(false)
            }, 2000)
        } else {
            setImgSizeError("Максимальный размер фото 2 Мб")
            alert("Максимальный размер фото 2 Мб")
            setChangingHeadWidePhoto(false)
        }
    }

    const deletePhoto = () => {
        setChangingPhoto(true)
        deleteShowAfisha(showId)
        setTimeout(() => {
            refetch()
            setChangingPhoto(false)
        }, 2000)

    }

    useEffect(() => {
        setIsLoading((isLoadingData || isPuting))
    }, [isLoadingData, isPuting])

    useEffect(() => {
        if (data) {
            setValue("name", data.name)
            setValue("nameEN", data.nameEN)
            setValue("ageCategory", data.ageCategory)
            setValue("shortDescription", data.shortDescription)
            setValue("quote", data.quote)
            setValue("quoteEN", data.quoteEN)
            setValue("shortDescriptionEN", data.shortDescriptionEN)
            setValue("linkVideo", data.linkVideo)
            setValue("director", data.director)
            setValue("directorEN", data.directorEN)
            setValue("duration", data.duration)
            setValue("durationEN", data.durationEN)
            setValue("priorityId", parseInt(data.priorityId))
            setValue("buyButtonText", data.buyButtonText)
            setValue("buyButtonTextEN", data.buyButtonTextEN)
            setValue("buyButtonLink", data.buyButtonLink)
            setBuyButtonStatus(data.buyButtonStatus)
            setHidden(data.hidden)
            setValueEditor(data.description)
            setValueEditorEn(data.descriptionEN)
        }
    }, [data])
    const onSubmit = (data) => {
        data.id = showId
        data.description = valueEditor;
        data.descriptionEN = valueEditorEn;
        putShowById(data).unwrap().then(() => {
            refetch()
            refetchShows()
        })
    }
    return (
        <Modal
            size={"xl"}
            show={showEditForm}
            onHide={() => {
                setShowId(null)
                reset()
                setShowEditForm(false)
            }}
            backdrop="static"
            keyboard={false}
        >

            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>Редактирование шоу</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!isLoading ?
                        <>
                            <div className="ms-auto me-0 text-end mt-3 mb-3">
                                <ButtonGroup size="sm">
                                    <Button variant="primary" onClick={() => {
                                        reset()
                                        setShowEditForm(!showEditForm)
                                    }
                                    }>Отменить</Button>
                                    <Button type="submit" variant="success">
                                        Сохранить
                                    </Button>
                                </ButtonGroup>
                            </div>
                            <Row>
                                <Col>

                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <div className="form-floating mb-3">
                                        <input autoComplete="off" type="text" className={`form-control ${errors.name && "is-invalid"}`} id="name" placeholder="Название шоу" {...register("name", { required: { value: true, message: "Поле не может быть пустым" } })} />
                                        <label htmlFor="name">Название шоу</label>
                                        {errors.name && <span className="invalid-feedback">{errors.name?.message}</span>}
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="form-floating mb-3">
                                        <input autoComplete="off" type="text" className="form-control" id="nameEN" placeholder="Название шоу EN" {...register("nameEN")} />
                                        <label htmlFor="nameEN">Название шоу EN</label>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <div className="form-floating mb-3">
                                        <input autoComplete="off" type="text" className="form-control" id="ShortDescription" placeholder="Краткое описание" {...register("shortDescription")} />
                                        <label htmlFor="shortDescription">Краткое описание</label>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="form-floating mb-3">
                                        <input autoComplete="off" type="text" className="form-control" id="ShortDescriptionEN" placeholder="Краткое описание EN" {...register("shortDescriptionEN")} />
                                        <label htmlFor="ShortDescriptionEN">Краткое описание EN</label>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <div className="form-floating mb-3">
                                        <input autoComplete="off" type="text" className="form-control" id="quote" placeholder="Цитата" {...register("quote")} />
                                        <label htmlFor="quote">Цитата</label>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="form-floating mb-3">
                                        <input autoComplete="off" type="text" className="form-control" id="quoteEN" placeholder="Цитата EN" {...register("quoteEN")} />
                                        <label htmlFor="quoteEN">Цитата EN</label>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col lg={"auto"}>
                                    <div className="form-floating mb-3">
                                        <input autoComplete="off" type="number" className="form-control" id="ageCategory" placeholder="Возрастная категория" {...register("ageCategory", { max: 18 })} />
                                        <label htmlFor="ageCategory">Возрастная категория</label>
                                    </div>
                                </Col>
                                <Col lg={"8"}>
                                    <div className="form-floating mb-3">
                                        <input autoComplete="off" type="text" className="form-control" id="LinkVideo" placeholder="Ссылка на видео" {...register("linkVideo")} />
                                        <label htmlFor="LinkVideo">Ссылка на видео</label>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <div className="form-floating mb-3">
                                        <input autoComplete="off" type="text" className="form-control" id="director" placeholder="Режисер" {...register("director")} />
                                        <label htmlFor="director">Режисер</label>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="form-floating mb-3">
                                        <input autoComplete="off" type="text" className="form-control" id="directorEN" placeholder="Режисер EN" {...register("directorEN")} />
                                        <label htmlFor="directorEN">Режисер EN</label>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <div className="form-floating mb-3">
                                        <input autoComplete="off" type="text" className="form-control" id="duration" placeholder="Режисер" {...register("duration")} />
                                        <label htmlFor="duration">Продолжительность</label>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="form-floating mb-3">
                                        <input autoComplete="off" type="text" className="form-control" id="durationEN" placeholder="Цитата EN" {...register("durationEN")} />
                                        <label htmlFor="durationEN">Продолжительность EN</label>
                                    </div>
                                </Col>
                            </Row>
                            <div className="form-check form-switch mt-1 mb-3">
                                <input className="form-check-input" onClick={() => { setBuyButtonStatus(!buyButtonStatus) }} checked={buyButtonStatus} type="checkbox" id="status" {...register("buyButtonStatus")} />
                                <label className="form-check-label" htmlFor="rememberMe">Показать кнопку "купить"</label>
                            </div>
                            {buyButtonStatus &&
                                <Row>
                                    <Col lg={4}>
                                        <div className="form-floating mb-3">
                                            <input autoComplete="off" type="text" className={`form-control ${errors.buyButtonText && "is-invalid"}`} id="buyButtonText" placeholder="Текст кнопки" {...register("buyButtonText", { required: { value: true, message: "Поле не может быть пустым" } })} />
                                            <label htmlFor="buyButtonText">Текст кнопки</label>
                                        </div>
                                        {errors.name && <span className="invalid-feedback">{errors.buyButtonText?.message}</span>}
                                    </Col>
                                    <Col lg={4}>
                                        <div className="form-floating mb-3">
                                            <input autoComplete="off" type="text" className="form-control" id="buyButtonTextEN" placeholder="Текст кнопки EN" {...register("buyButtonTextEN")} />
                                            <label htmlFor="buyButtonTextEN">Текст кнопки EN</label>
                                        </div>
                                    </Col>
                                    <Col lg={4}>
                                        <div className="form-floating mb-3">
                                            <input autoComplete="off" type="text" className={`form-control ${errors.buyButtonLink && "is-invalid"}`} id="buyButtonLink" placeholder="Ссылка" {...register("buyButtonLink", { required: { value: true, message: "Поле не может быть пустым" } })} />
                                            <label htmlFor="buyButtonLink">Ссылка</label>
                                        </div>
                                        {errors.name && <span className="invalid-feedback">{errors.buyButtonLink?.message}</span>}
                                    </Col>
                                </Row>
                            }
                            <div className="form-check form-switch mt-1 mb-3">
                                <input className="form-check-input" onClick={() => { setHidden(!hidden) }} checked={hidden} type="checkbox" id="status" {...register("hidden", {})} />
                                <label className="form-check-label" htmlFor="rememberMe">Скрыть</label>
                            </div>
                            <Row>
                                <Col xs={'auto'}>
                                    <FloatingLabel
                                        controlId="floatingTitile"
                                        label="Приоритет отображения"
                                        className="mb-3 m-auto"
                                    >
                                        <Form.Control autoComplete="off" type="number"
                                            placeholder="Название выставки" {...register("priorityId")} />
                                    </FloatingLabel>
                                </Col>
                            </Row>

                            <div className="mb-3">
                                <label>Описание</label>
                                <ReactQuillLC valueEditor={valueEditor} setValueEditor={setValueEditor} />
                            </div>
                            <div className="mb-3">
                                <label>Описание EN</label>
                                <ReactQuillLC valueEditor={valueEditorEn} setValueEditor={setValueEditorEn} />
                            </div>
                            <div className="ms-auto me-0 text-end mt-3 mb-3">
                                <ButtonGroup size="sm">
                                    <Button variant="primary" onClick={() => {
                                        reset()
                                        setShowEditForm(!showEditForm)
                                    }
                                    }>Отменить</Button>
                                    <Button type="submit" variant="success">
                                        Сохранить
                                    </Button>
                                </ButtonGroup>
                            </div>
                            <div>
                                <Row className="justify-content-center g-2">
                                    <Col lg={4} xs={6}>
                                        <div>
                                            <label htmlFor="imageHead" className="form-label">Изображение верхнего блока и горизонтальной афиши</label>
                                            <div className="card mb-3">
                                                {changingHeadPhoto ? <LoadScreenMin /> :
                                                    <div className="mb-3 text-center">
                                                        <img key={Date.now()} src={data?.headLayoutImageType ? `/api/shows/head-img/${data.id}` : `/NoPhoto.png`} className="img-fluid" alt="Shows head img" />
                                                    </div>
                                                }
                                            </div>
                                            <div className="mt-3 mb-1">
                                                <u><span>Рекомендованное соотношние сторон: 16:9</span></u>
                                            </div>
                                            <div className='input-group'>
                                                <input onChange={(e) => onHeadImageChange(e)}
                                                    id="photo"
                                                    name="photo"
                                                    className="form-control form-control-sm"
                                                    accept=".jpg, .jpeg, .png" type="file" />
                                            </div>
                                            <div>
                                                {imgSizeError && <span className='text-danger'>{imgSizeError}</span>}
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="imageHead" className="form-label">Широкое изображение верхнего блока</label>
                                            <div className="card mb-3">
                                                {changingHeadWidePhoto ? <LoadScreenMin /> :
                                                    <div className="mb-3 text-center">
                                                        <img key={Date.now()} src={data?.headLayoutWideImageType ? `/api/shows/head-wide-img/${data.id}` : `/NoPhoto.png`} className="img-fluid" alt="Shows head img" />
                                                    </div>
                                                }
                                            </div>
                                            <div className="mt-3 mb-1">
                                                <u><span>Рекомендованное соотношние сторон: 1217∶270</span></u>
                                            </div>
                                            <div className='input-group'>
                                                <input onChange={(e) => onHeadImageWideChange(e)}
                                                    id="photo"
                                                    name="photo"
                                                    className="form-control form-control-sm"
                                                    accept=".jpg, .jpeg, .png" type="file" />
                                            </div>
                                            <div>
                                                {imgSizeError && <span className='text-danger'>{imgSizeError}</span>}
                                            </div>
                                        </div>
                                        <div className="mt-3">
                                            <label htmlFor="imageHead" className="form-label">Шаблон билета</label>
                                            <div className="card mb-3">
                                                {changingHeadWidePhoto ? <LoadScreenMin /> :
                                                    <div className="mb-3 text-center">
                                                        <img key={Date.now()} src={data?.ticketImageType ? `/api/shows/ticekt-img/${data.id}?select=show` : `/NoPhoto.png`} className="img-fluid" alt="Shows head img" />
                                                    </div>
                                                }
                                            </div>
                                            <div className="mt-3 mb-1">
                                                <u><span>Рекомендованное соотношние сторон: 240∶113</span></u>
                                            </div>
                                            <div className='input-group'>
                                                <input onChange={(e) => onTicketChange(e)}
                                                    id="photo"
                                                    name="photo"
                                                    className="form-control form-control-sm"
                                                    accept=".jpg, .jpeg, .png" type="file" />
                                            </div>
                                            <div>
                                                {imgSizeError && <span className='text-danger'>{imgSizeError}</span>}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={4} xs={6}>
                                        <label htmlFor="image" className="form-label">Изображение афиши</label>
                                        <div className="card mb-3">
                                            {changingPhoto ? <LoadScreenMin /> :
                                                <div className="mb-3 text-center">
                                                    <img key={Date.now()} src={data?.afishaImageType ? `/api/shows/afisha-img/${data.id}` : `/NoPhoto.png`} className="img-fluid" alt="Shows afisha img" />
                                                </div>
                                            }
                                        </div>
                                        <div className="mt-3 mb-1">
                                            <u><span>Рекомендованное соотношние сторон: 2∶3</span></u>
                                        </div>
                                        <div className='input-group'>
                                            <input onChange={(e) => onImageChange(e)}
                                                id="photo"
                                                name="photo"
                                                className="form-control form-control-sm"
                                                accept=".jpg, .jpeg, .png" type="file" />
                                        </div>
                                        <div>
                                            {imgSizeError && <span className='text-danger'>{imgSizeError}</span>}
                                        </div>
                                    </Col>
                                </Row>

                            </div>
                        </>
                        :
                        <LoadScreenMin height="30" />
                    }
                </Modal.Body>

                <Modal.Footer>
                    <ButtonGroup size="sm">
                        <Button variant="primary" onClick={() => {
                            reset()
                            setShowEditForm(!showEditForm)
                        }
                        }>Отменить</Button>
                        <Button type="submit" variant="success">
                            Сохранить
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </form>

        </Modal>
    )
} 