import { useState } from "react";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap"
import { Helmet } from "react-helmet"
import { useFieldArray, useForm } from "react-hook-form";


export const RefundApplication = ({ t }) => {
    const { register, handleSubmit, formState: { errors }, control } = useForm();
    const { fields, append, remove } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormProvider)
        name: "ticket", // unique name for your Field Array
    });
    const [selDocName, setSelDocName] = useState()
    const [selTypeTicekts, setSelTypeTicekts] = useState()
    const submit = (data) => {
        
        console.log(data)
    }

    // useEffect(() => {
    //     // console.log(errors.lastname)
    //     // console.log(errors.lastname?.message)
    //     // console.log(errors.lastname === null ? false : true)
    // }, [])

    return (
        <>
            <Helmet>
                <title>{t("mainTag.title")}</title>
                <meta name="description" content='Первое в России шоу-пространство "Ленинград Центр"'></meta>
                <meta name="og:title" content={`${t("mainTag.title")}`} />
                <meta name="og:description" content='Первое в России шоу-пространство "Ленинград Центр"' />
                <meta name="keywords" content="шоу- пространство, шоу пространство, ленинград центр, центр ленинград, мюзикл, шоу, представление, афиша" c />
                {/* <meta property="og:image" content={data.headLayoutImageType ? `/api/shows/head-wide-img/${data.id})` : "/Show/091A8332.jpg"} /> */}
            </Helmet>
            <div className="about-haed-layout mb-3">
                <div className="about-head-layout-img" style={{ backgroundImage: `url("/AboutProjcet/about-project.jpg")` }}>
                </div>
                <div className="about-head-mask">
                    <div className="justify-content-end ms-auto me-auto justify-content-end rounded-3"
                        style={{ width: "70%", backgroundColor: "#00000006", padding: "1rem" }}>
                    </div>
                </div>
            </div>
            <Container>
                <Row className="justify-content-center">
                    <Col lg={8}>
                        <Form className="justify-content-center" onSubmit={handleSubmit(submit)}>
                            <div className="d-md-flex gap-1 justify-content-center mb-2">
                                <Form.Group className="flex-fill mb-1">
                                    <>
                                        <Form.Label htmlFor="lastname">{t("form.lastName")}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="lastname"
                                            placeholder={t("form.lastName")}
                                            {...register("lastname", { required: { value: true, message: "Заполните пожалуйсто поле" } })}
                                            isInvalid={errors.lastname}
                                        // isValid
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.lastname?.message}
                                        </Form.Control.Feedback>
                                    </>
                                </Form.Group>
                                <Form.Group className="flex-fill mb-1">
                                    <>
                                        <Form.Label htmlFor="firstName">{t("form.firstName")}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="firstName"
                                            placeholder={t("form.firstName")}
                                            {...register("firstname", { required: { value: true, message: "Заполните пожалуйсто поле" } })}
                                            isInvalid={errors.firstname}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.firstname?.message}
                                        </Form.Control.Feedback>
                                    </>
                                </Form.Group>
                                <Form.Group className="flex-fill mb-1">
                                    <>
                                        <Form.Label htmlFor="patronymic">{t("form.patronymic")}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="patronymic"
                                            placeholder={t("form.patronymic")}
                                            {...register("patronymic")}
                                        />
                                    </>
                                </Form.Group>
                            </div>
                            <Form.Group className="border-top pt-3">
                                <Form.Label>Документ удостоверяющий личность</Form.Label>
                                <div className="mb-1 flex-fill">
                                    <InputGroup>
                                        <InputGroup.Text>
                                            Документ
                                        </InputGroup.Text>
                                        <Form.Select
                                            {...register("selDocName", { onChange: (e) => { setSelDocName(e.target.value) }, required: { value: true } })}>
                                            <option value={1}>Паспорт гражданина РФ</option>
                                            <option value={2}>Загранпаспорт</option>
                                            <option value={3}>Военный билет</option>
                                            <option value={4}>Иной документ</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.selDocName}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </div>
                                {selDocName == 4 &&
                                    <Form.Group className="gap-1 justify-content-center">
                                        <Form.Label>{t("form.docName")}</Form.Label>
                                        <Form.Control placeholder={t("form.docName")}
                                            className="mb-1 flex-fill"
                                            {...register("docName", { required: { value: true, message: "" } })}
                                            isInvalid={errors.docName}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Укажите наименование документа
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                }
                                <div className="d-md-flex gap-1 justify-content-center mb-2">
                                    <Form.Group className="flex-fill mb-1">
                                        <>
                                            <Form.Label htmlFor="seriesDoc">Серия</Form.Label>
                                            <Form.Control
                                                type="text"
                                                id="seriesDoc"
                                                placeholder={"Серия"}
                                                {...register("seriesDoc", { required: { value: true, message: "" } })}
                                                isInvalid={errors.seriesDoc}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Укажите серию документа
                                            </Form.Control.Feedback>
                                        </>
                                    </Form.Group>
                                    <Form.Group className="flex-fill mb-1">
                                        <>
                                            <Form.Label htmlFor="numberDoc">Номер</Form.Label>
                                            <Form.Control
                                                type="text"
                                                id="numberDoc"
                                                placeholder={"Номер"}
                                                {...register("numberDoc", { required: { value: true, message: "" } })}
                                                isInvalid={errors.numberDoc}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Укажите номер документа
                                            </Form.Control.Feedback>
                                        </>
                                    </Form.Group>
                                    <Form.Group className="flex-fill mb-1">
                                        <>
                                            <Form.Label htmlFor="seriesDoc">Дата выдачи</Form.Label>
                                            <Form.Control
                                                type="date"
                                                {...register("issuedDoc", { required: { value: true, message: "" } })}
                                                id="issuedDoc"
                                                placeholder={"Дата выдачи"}
                                                isInvalid={errors.issuedDoc}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Укажите дату выдачи документа
                                            </Form.Control.Feedback>
                                        </>
                                    </Form.Group>
                                </div>
                                <div className="d-md-flex gap-1 justify-content-center mb-2">
                                    <Form.Group className="gap-1 flex-fill">
                                        <Form.Label>Кем выдан</Form.Label>
                                        <Form.Control placeholder={"Кем выдан"}
                                            {...register("issuingAuthority", { required: { value: true, message: "" } })}
                                            isInvalid={errors.issuingAuthority}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Укажите дату выдачи документа
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                            </Form.Group>
                            <div className="d-md-flex gap-1 justify-content-center mb-2">
                                <Form.Group className="gap-1 flex-fill">
                                    <Form.Label>Вид билетов</Form.Label>
                                    <Form.Select placeholder={"Вид билетов"}
                                        defaultValue={""}
                                        {...register("typeTicekts",
                                            {
                                                required: { value: true, message: "Укажите вид билета (-ов)" },
                                                onChange: (e) => { setSelTypeTicekts(e.target.value) }
                                            })}
                                        isInvalid={errors.typeTicekts}>
                                        <option value={""} hidden> --- укажите вид билета (-ов) ---</option>
                                        <option value={1}>Бумажные билеты</option>
                                        <option value={2}>Электронные билеты</option>
                                        <option value={3}>Кассовый чек</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Укажите вид билета (-ов)
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                            <div>
                                <div className="d-md-flex gap-1 justify-content-center mb-2">
                                    <Form.Group className="gap-1 flex-fill">
                                        <Form.Label>Наименование мероприятия</Form.Label>
                                        <Form.Control placeholder={"Шоу \"Illusio. Повелитель грез\""}
                                            {...register("showName", { required: { value: true, message: "" } })}
                                            isInvalid={errors.showName}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Укажите наименование мероприятия
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    {selTypeTicekts == 2 &&
                                        <Form.Group className="gap-1 flex-fill">
                                            <Form.Label>Номер заказа</Form.Label>
                                            <Form.Control placeholder={"Номер заказа"}
                                                {...register("orderNum", { required: { value: true, message: "" } })}
                                                type="text"
                                                isInvalid={errors.orderNum}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Укажите номер заказа
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    }
                                    <Form.Group className="gap-1 flex-fill">
                                        <Form.Label>Стоимость билета (-ов) (сумма)  </Form.Label>
                                        <Form.Control placeholder={"10000.00"}
                                            {...register("amount", { required: { value: true, message: "" } })}
                                            type="number" step='0.01'
                                            isInvalid={errors.amount}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Укажите стоимость билета (-ов) (сумма)
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                            </div>
                            <div>
                                <Form.Label>Место размещения (ряд, место)</Form.Label>
                                {fields.map((item, index) => (
                                    <div key={item.id} className="d-md-flex gap-1 mb-2">
                                        <Form.Group className="gap-1">
                                            {index == 0 && <Form.Label>Ряд или ложа</Form.Label>}
                                            <Form.Control placeholder={"VIP Ложа 5"}
                                                {...register(`ticket.${index}.row`, { required: { value: true, message: "" } })}
                                                type="ticket"
                                                isInvalid={errors.ticket?.[index]?.row}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Укажите ряд или номер ложи
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="gap-1">
                                            {index == 0 && <Form.Label>Место</Form.Label>}
                                            <InputGroup>
                                                <Form.Control placeholder={10}
                                                    {...register(`ticket.${index}.seat`, { required: { value: true, message: "" } })}
                                                    type="text"
                                                    isInvalid={errors.ticket?.[index]?.seat}

                                                />
                                                <Button type="button" onClick={() => remove(index)}>X</Button>
                                            </InputGroup>
                                            <Form.Control.Feedback type="invalid">
                                                Укажите место
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                ))}
                            </div>
                            <div className="mb-3">
                                <Button
                                    type="button"
                                    onClick={() => append({ row: "", seat: "" })}
                                >
                                    Добавить ряд и место
                                </Button>
                            </div>
                            <Button type="submit">Подготовит</Button>
                            
                        </Form>
                    </Col>
                </Row>
            </Container >
        </>
    )
}