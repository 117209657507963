import { Route, Routes, useLocation } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import useToken from './features/useToken'
import { News } from './components/News/News';
import { TheaterTroupe } from './components/ProjectLC/TheaterTroupe';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Show } from './components/Show/Show';
import { NotFound } from './components/NotFound';
import { AboutProject } from './components/ProjectLC/AboutProject';
import { Nikalas } from './components/Show/Nikalas';
import { LayoutAbout } from './components/LayoutAbout';
import { History } from './components/ProjectLC/History';
import { Stage } from './components/ProjectLC/Stage';
import { Restaurant } from './components/ProjectLC/Restaurant';
import { Troupe } from './components/ProjectLC/Ttroupe';
import { Shows } from './components/ProjectLC/Shows';
import { Bars } from './components/ProjectLC/Bars';
import { Qualitymark } from './components/ProjectLC/Qualitymark';
import { LayoutAdmin } from './components/LayoutAdmin';
import { Users } from './components/Admin/User/Users';
import { LoginAdminForm } from './components/Admin/LoginAdminForm';
import { ShowsList } from './components/Admin/Show/ShowsList';
import { Logout } from './features/Logout';
import { NewsList } from './components/Admin/News/NewsList';
import { TroupeList } from './components/Admin/Ttroupe/TtroupeList';
import { ExhibitionsList } from './components/Admin/Exhibition/ExhibitionsList';
import { Documents } from './components/Documents';
import { DocumentationsEdit } from './components/Admin/Documentations/DocumentationsEdit';
import { Gallery } from './components/ProjectLC/Gallery';
import { Partners } from './components/ProjectLC/Partners';
import { Client } from './components/Client/Clinet';
import useClientToken from './features/useClientToken';
import { Exhibition } from './components/Exhibition/Exhibition';
import { Corporate } from './components/ProjectLC/Corporate';
import { ClientTicketsOrders } from './components/Client/ClientTicketsOrders';
import { ClientResetPass } from './components/Client/ClientResetPass';
import { ClientRecovery } from './components/Client/ClientRecovery';
import { ClientRegistration } from './components/Client/ClientRegistration';
import { SchemeHall } from './components/SchemeHall/SchemeHall';
import { SchemesHall } from './components/Admin/SchemeHall/SchemesHall';
import { SchemeEdit } from './components/Admin/SchemeHall/SchemeEdit';
import { HallBind } from './components/Admin/SchemeHall/HallBind';
import { PayTickets } from './components/Show/PayTickets';
import { SchemeSession } from './components/Admin/SchemeHall/SchemeSession';
// import { Exhibitions } from './components/Exhibition/Exhibitions';
import { ExhibitionsAll } from './components/ProjectLC/ExhibitionsAll';
import { CookiesPolicy } from './components/CookiesPolicy';
import { useGetVersionQuery } from './features/CallApi';
import { LoadScreen } from './components/UI/LoadScreen';
import { NewsPostPage } from './components/News/NewsPostPage';
import { Afisha } from './components/Afisha/Afisah';
import { NikalasTest } from './components/Show/NikalasTest';
import { Cabinet } from './components/Client/Cabinet';
import { Certificate } from './components/Certificate/Certificate';
import { Requests } from './components/Requests/Requests';
import { RedirectUrls } from './components/Admin/RedirectUrls/RedirectUrls';
import { OrderTickets } from './components/Client/OrderTickets';
import { LayoutScheme } from './components/LayoutScheme';
import { StageV2 } from './components/SchemeHall/StageV2';
import { PayTicketsV2 } from './components/Show/PayTicketsV2';
import { ClientLoginSms } from './components/Client/ClientLoginSms';
import { ClientVkAuht } from './components/Client/ClientVkAuht';
import { RefundApplication } from './components/Requests/RefundApplication';
// import { Certificate } from './components/Certificate/Certificate';




export const App = () => {
  const { t, i18n } = useTranslation("global");
  const [selLanguage, setSelLanguage] = useState(localStorage.getItem("selLng") === null ? "ru" : localStorage.getItem("selLng"))
  const [reserFormShow, setReserFormShow] = useState(false)
  const [aboutMobMenu, setAboutMobMenu] = useState()
  // const { data: version } = useGetVersionQuery()
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const { token, setTokenData, loadingSite, role, getRefreshToken } = useToken();
  const { clientToken, setClientTokenData, getClientRefreshToken } = useClientToken();

  const changanLanguage = (language) => {
    i18n.changeLanguage(language)
    localStorage.setItem("selLng", language)
    setSelLanguage(language)
    // document.title = t("mainTag.title")
  }

  useEffect(() => {
    if (selLanguage) {
      i18n.changeLanguage(selLanguage)
    }
  }, [selLanguage])

  if (loadingSite) {
    return (
      <LoadScreen />
    )
  }
  return (
    <>
      <Routes>
        <Route path='/' element={
          <Layout
            changanLanguage={changanLanguage}
            selLanguage={selLanguage}
            reserFormShow={reserFormShow}
            setReserFormShow={setReserFormShow}
            aboutMobMenu={aboutMobMenu}
            setAboutMobMenu={setAboutMobMenu}
            loadingSite={loadingSite}
            clientToken={clientToken}
          />}>
          <Route index element={<Home selLanguage={selLanguage} />} />
          <Route path='cookiespolicy' element={<CookiesPolicy />} />
          {/* <Route element={<Afisha />} /> */}
          <Route path='news' element={<News selLanguage={selLanguage} />} />
          <Route path='news/post/:seoUrl' element={<NewsPostPage selLanguage={selLanguage} />} />
          <Route path='theater-troupe' element={<TheaterTroupe />} />
          <Route path='about-project/show/:seoUrl' element={<Show />} />
          <Route path='about-project/exhibition/:seoUrl' element={<Exhibition />} />
          {/* <Route path='testnikolas/:id' element={<NikalasTest selLanguage={selLanguage} />} /> */}
          {/* <Route path='paytickets/:id' element={<PayTickets selLanguage={selLanguage} clientToken={clientToken} />} /> */}
          <Route path='logout' element={<Logout />} />
          <Route path='/notfonund' element={<NotFound />} />
          <Route path='documents' element={<Documents />} />
          <Route path='demo/client/login' element={<ClientLoginSms selLanguage={selLanguage} t={t} clientToken={clientToken} setClientTokenData={setClientTokenData} getClientRefreshToken={getClientRefreshToken} />} />
          <Route path='client/profile' element={<Client clientToken={clientToken} setClientTokenData={setClientTokenData} getClientRefreshToken={getClientRefreshToken} />} />
          <Route path='client/orders' element={<ClientTicketsOrders clientToken={clientToken} setClientTokenData={setClientTokenData} getClientRefreshToken={getClientRefreshToken} />} />
          <Route path='client/resetpass' element={<ClientResetPass selLanguage={selLanguage} />} />
          <Route path='client/clientrecovery' element={<ClientRecovery selLanguage={selLanguage} />} />
          <Route path='client/registration' element={<ClientRegistration selLanguage={selLanguage} />} />
          <Route path='afisha' element={<Afisha selLanguage={selLanguage} />} />
          <Route path='cabinet' element={<Cabinet />} />
          <Route path='client/tickets/order/:id' element={<OrderTickets />} />
          <Route path='client/vk-auth' element={<ClientVkAuht t={t} setClientTokenData={setClientTokenData} />} />
          <Route path='refund/tickets' element={<RefundApplication clientToken={clientToken} t={t} />}/>
          <Route path='*' element={<Home selLanguage={selLanguage} />} />
        </Route>
        <Route path='about-project' element={<LayoutAbout
          changanLanguage={changanLanguage}
          selLanguage={selLanguage}
          reserFormShow={reserFormShow}
          setReserFormShow={setReserFormShow}
          aboutMobMenu={aboutMobMenu} setAboutMobMenu={setAboutMobMenu}
          loadingSite={loadingSite}
          clientToken={clientToken}
        />}>
          <Route path='' index element={<AboutProject selLanguage={selLanguage} />} />
          <Route path='history' element={<History selLanguage={selLanguage} />} />
          <Route path='stage' element={<Stage selLanguage={selLanguage} />} />
          <Route path='restaurant' element={<Restaurant selLanguage={selLanguage} />} />
          <Route path='shows' element={<Shows selLanguage={selLanguage} />} />
          <Route path='troupe' element={<Troupe selLanguage={selLanguage} />} />
          <Route path='bars' element={<Bars selLanguage={selLanguage} reserFormShow={reserFormShow} setReserFormShow={setReserFormShow} />} />
          <Route path='gallery' element={<Gallery selLanguage={selLanguage} />} />
          <Route path='certificates' element={<Certificate selLanguage={selLanguage} />} />
          <Route path='znak-kachestva' element={<Qualitymark selLanguage={selLanguage} />} />
          <Route path='partners' element={<Partners selLanguage={selLanguage} />} />
          <Route path='corporate' element={<Corporate selLanguage={selLanguage} />} />
          <Route path='exhibitions' element={<ExhibitionsAll selLanguage={selLanguage} />} />
          <Route path='requests' element={<Requests />} />
        </Route>
        <Route path='admin' element={<LayoutAdmin
          token={token}
          role={role}
          getRefreshToken={getRefreshToken}
          loadingSite={loadingSite}
        />}>
          <Route path='login' element={<LoginAdminForm token={token} setTokenData={setTokenData} />} />
          <Route path='users' element={<Users />} />
          <Route path='shows' element={<ShowsList />} />
          <Route path='news' element={<NewsList />} />
          <Route path='troupe' element={<TroupeList selLanguage={selLanguage} />} />
          <Route path='exhibition' element={<ExhibitionsList />} />
          <Route path='documentations' element={<DocumentationsEdit />} />
          <Route path='schemeshall' element={<SchemesHall />} />
          <Route path='schemeedit/:id' element={<SchemeEdit />} />
          <Route path='schemeedit-v2/:id' element={<PayTicketsV2 selLanguage={selLanguage} clientToken={clientToken} />} />
          <Route path='schemesession/:id' element={<SchemeSession />} />
          <Route path='hallbind/:id' element={<HallBind />} />
          <Route path='canvas' element={<SchemeHall />} />
          <Route path='redirecturls' element={<RedirectUrls />} />
        </Route>
        <Route path="paytickets" element={<LayoutScheme
          changanLanguage={changanLanguage}
          selLanguage={selLanguage}
          reserFormShow={reserFormShow}
          setReserFormShow={setReserFormShow}
          aboutMobMenu={aboutMobMenu} setAboutMobMenu={setAboutMobMenu}
          loadingSite={loadingSite}

        />}>
          {/* <Route path=':id' element={<PayTickets selLanguage={selLanguage} clientToken={clientToken} />} /> */}
          <Route path=':id' element={<PayTicketsV2 selLanguage={selLanguage}
            setClientTokenData={setClientTokenData}
            clientToken={clientToken} />} />
        </Route>
        <Route path="testnikolas" element={<LayoutScheme
          changanLanguage={changanLanguage}
          selLanguage={selLanguage}
          reserFormShow={reserFormShow}
          setReserFormShow={setReserFormShow}
          aboutMobMenu={aboutMobMenu} setAboutMobMenu={setAboutMobMenu}
          loadingSite={loadingSite}
        />}>
          <Route path=':id' element={<NikalasTest selLanguage={selLanguage} clientToken={clientToken} />} />
        </Route>
        <Route path="tickets" element={<LayoutScheme
          changanLanguage={changanLanguage}
          selLanguage={selLanguage}
          reserFormShow={reserFormShow}
          setReserFormShow={setReserFormShow}
          aboutMobMenu={aboutMobMenu} setAboutMobMenu={setAboutMobMenu}
          loadingSite={loadingSite}
        />}>
          <Route path=':id' element={<Nikalas selLanguage={selLanguage} clientToken={clientToken} />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
